<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 611.31 610.29"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M611.05,280q0-40.34,0-80.67a58.88,58.88,0,0,0-1.39-14.21c-5.45-21.72-23.76-35.87-46.65-36-15-.11-30.24-.09-45-.06l-19.55,0h-.12V0H112.47V149.09h-.3c-6.37,0-12.83,0-19.08.09-14,.1-28.54.21-42.74-.22a48.35,48.35,0,0,0-35.52,13.63A49.32,49.32,0,0,0,0,198.48C.17,242.35.17,287,.17,330.1c0,38.62,0,78.56.13,117.84,0,5.93.82,14.67,4.55,22.6,8.46,18,23.71,27.54,44.1,27.55h63.68v112.2H498.78V498.08c5.17,0,10.33-.06,15.36-.09,15.81-.12,30.75-.22,46,.24a49.15,49.15,0,0,0,36.24-13.89c9.74-9.5,15-22.66,15-37C611,391.56,611,334.87,611.05,280ZM449.7,48V148.67H161.36V48Zm.1,318.53V561.93H161.55V366.52ZM112.9,318.19v130.9H50.68c-.87,0-1.53,0-2,0,0-.45,0-1,0-1.77q0-124,0-247.94c0-.35,0-1.05,0-1.73h1.48q255.45,0,510.89,0h1.59c0,.46,0,1,0,1.62v64.6q0,91.92,0,183.85c0,.94,0,1.55-.07,1.94h-.44c-12.12-.07-24.39-.08-36.44-.08H498.54V318.19Z"/><path d="M517.37,247.82a24.64,24.64,0,0,0-22.91-23.62c-2.49-.15-4.92-.11-7.27-.07l-3.05,0h-1.6l-1.76,0c-2.58,0-5.26-.09-8,.08a24.09,24.09,0,0,0-22.51,23.3c-.48,12.94,8.18,23.21,20.6,24.4,4.19.41,8.46.61,12.78.61s8.4-.19,12.64-.57A23.3,23.3,0,0,0,517.37,247.82Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconPrint',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
